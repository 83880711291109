<template>
  <div class="box">
    <div style="width: 1900px;margin: 0 auto;">
      <img style="width: 100%;" src="../assets/imgs/img_bg02.png" alt="">
    </div>
    <div class="case">
      <h2 class="banner">合作企业-案例</h2>
      <div class="img">
        <img src="../assets/imgs/img_line.png" alt="">
      </div>




      <div v-for="item, i in lists" :key="i">
        <div style="display: flex;justify-content:space-between;padding: 0 10px;">
          <div class="case_left">
            <div :class="`title${i+1}`">{{ item.title }}</div>
            <div class="bg"></div>
            <p :class="`content${i+1}`">{{ item.content }}</p>
          </div>
          <div :class="{'content_img': i!=0}">
            <img :src="item.img" alt="">
          </div>
        </div>

        <div
          style="display: flex;height: 526px;background-color: #ECF0FF;margin-top: -40px;">
          <div class="left_img">
            <img :src="item.img2" alt="">
          </div>
          <div class="case_left">
            <div :class="`title2${i+1}`">{{ item.title2 }}</div>
            <div class="bg"></div>
            <p class="contentr">{{ item.content2 }}</p>
          </div>
        </div>
      </div>
    </div>


    <!-- 更多企业选择帝集 -->
    <div class="teamwork">
      <h2 class="banner">更多企业选择帝集</h2>
      <div class="teamwork_img">
        <img src="../assets/imgs/img_line.png" alt="">
      </div>
      <div class="teamwork_box">
        <img class="top_img" src="../assets/imgs/img_firm_01.png" alt="">
        <img class="top_img" src="../assets/imgs/img_firm_02.png" alt="">
        <img class="top_img" src="../assets/imgs/img_firm_03.png" alt="">
        <img class="top_img" src="../assets/imgs/img_firm_04.png" alt="">
        <img class="top_img" src="../assets/imgs/img_firm_05.png" alt="">
        <img class="top_img" src="../assets/imgs/img_firm_06.png" alt="">
        <img src="../assets/imgs/img_firm_07.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_08.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_09.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_10.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_11.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_12.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_13.png" alt="">
        <img src="../assets/imgs/img_firm_14.png" style="margin-top: 10px;" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lists: [
        {
          title: '游戏类—魔域',
          img: require('../assets/imgs/img_photo_00.png'),
          content: '二个月完成10万左右的需求，附带15%的不定额充值金额，搭配自然量做出真实信息流数据效果。',
          title2: '游戏类—神武4',
          img2: require('../assets/imgs/img_photo_01.png'),
          content2: '二个月完成15万补量，全部真人真量，并且附带20%的不定额充值金额，完成媒体方给的比例要求吧数据做的真实完美。'
        },
        {
          title: '美的会员',
          img: require('../assets/imgs/img_photo_02.png'),
          content: '从2020年8月份开始推广每个月30-50万小程序授权拉新，做到2021年5月份美的会员结束，总量达到550万量级（社群+充电桩自然量一起配比完成），次留达到30%周留存达到3%。',
          title2: '美的美居',
          img2: require('../assets/imgs/img_photo_03.png'),
          content2: '会员结束后美居从2021年5月份开始推广，每个月50-100万授权拉新量级，全部由我们公司完成，截止到2022年每月仍继续在推广，次留达到30%周留存达到3%，中途新增了停车场、共享单车资源，协助充电桩和社群量一起完成。'
        },
        {
          title: '银行类—新一贷',
          img: require('../assets/imgs/img_photo_04.png'),
          content: '从2021年1月份开始每个月12-15万量级做H5纯注册补量，截止2022年5月 份做了120万+注册，现仍然再继续补量。',
          title2: '银行类—工银小豆',
          img2: require('../assets/imgs/img_photo_05.png'),
          content2: '开户+支付2021年2个月时间完成40万+的量级，因产品升级暂停推广。预计下半 年继续开放推广，独家产品。'
        },
        {
          title: '众安保险',
          img: require('../assets/imgs/img_photo_06.png'),
          content: '从2021年10月份开始推广，每个月3万量级需支付本金12元，按时完成甲方 的需求，该项目一直延续至今。',
          title2: '滴滴出行',
          img2: require('../assets/imgs/img_photo_07.png'),
          content2: '2022年3月份完成滴滴小程序100万+唤醒，4月份开始完成15万+唤醒量，得到 甲方的一致认可，成功拿下1年推广预算。'
        },
        {
          title: '龙湖天街小程序',
          img: require('../assets/imgs/img_photo_08.png'),
          content: '从2020年10月份开始推广，前期每个月6-10万量级，从2021年开始每个月 30万量级，5月份开始每个月30-50万量，全部顺利完成甲方安排的量级，并 且超额完成目标，成功拿下天街一整年的推广预算。',
          title2: 'KUKEY教育',
          img2: require('../assets/imgs/img_photo_09.png'),
          content2: '2021年3月份完成5000笔支付，单笔支付800元。6月份完成12000多笔订单，单 笔支付800元，本金达1000万+，均为任务量补效果非自然量。'
        },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  margin-top: 80px;
}

.bg {
  width: 70px;
  height: 6px;
  background: #3F7BFE;
  border-radius: 3px;
}

.banner {
  text-align: center;
  font-size: 32px;
  color: #4E7CFF;
  font-weight: 500px;
  margin-bottom: 13px;
}

.img {
  text-align: center;
  margin-bottom: 32px;
}

.case {
  width: 1000px;
  margin: 0 auto;
  margin-top: 32px;
}

.left_img {
  margin-left: 10px;
  margin-right: 40px;
  margin-top: 130px;
  /* margin-right: 30px; */
}

.case_left {
  margin-top: 100px;
}

.case_left>div {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 8px;
}

.case_left>p {
  width: 360px;
  color: #666666;
  font-size: 14px;
  text-align: justify;
  line-height: 30px;
}
.content1{
  margin-top: 90px;
}
.content2{
  margin-top: 40px;
}
.content3,
.content4,
.content5,
.contentr{
  margin-top: 70px;
}
.content_img{
  margin-top: -40px;
}

.teamwork {
    width: 1000px;
    margin: 0 auto;
    margin-top: 80px;

    .teamwork_img {
      text-align: center;
      margin-top: 13px;
    }


    .teamwork_box {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;

      img {
        width: 120px;
        height: 60px;
      }

      .top_img {
        margin-right: 26px;
      }

      .down_img {
        margin-right: 26px;
        margin-top: 10px;
      }
    }
  }
</style>
